import React, {useState, useEffect, useRef}  from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as _  from 'lodash'
import ChartItemContainer from '../../component/master/chart-item'
import TableItemContainer from '../../component/master/table-item'
import SkeletonContainer from '../../component/skeleton/master'


const Master = (props) => {
  const {dataFetch} = props
  const [isLoading, setIsLoading] = useState(false)
  const [screenHeight, setScreenHeight] = useState(null)
  const divRef = useRef();

  useEffect(() => {
    setScreenHeight(divRef.current.clientHeight)
  },[])

  useEffect(()=> {
    setIsLoading(dataFetch.isLoading)
  }, [dataFetch])

  // Render container item
  const renderContainerItem = () => {
    const data = _.get(props, 'dataFetch.data', [])
    return _.map(data, (item) => 
    {
      switch (item.site_type) {
        case 'chart':
          return <ChartItemContainer screenHeight={screenHeight} key={item.code} data={item} currentTheme={props.currentTheme}/>
        case 'list':
          return <TableItemContainer screenHeight={screenHeight} key={item.code} data={item} currentTheme={props.currentTheme}/>
        default:
          break;
      }
    })
  }

  return (
    <Container ref={divRef}>
      {isLoading ? <SkeletonContainer screenHeight={screenHeight}/> : renderContainerItem()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f1f1f1;
  margin-top: 70px;
  padding: 5px;
  height: calc((100%) - 90px);
`;

const mapStateToProps = (state) => {
  return {
    dataFetch: {...state.dataMaster},
  };
};

export default connect(mapStateToProps) (Master);
