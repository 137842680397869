import { style } from "openlayers";
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import avartarBackground from '../../assets/image/background.jpeg'


const InfoContainer = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    return (
        <Info imgsrc={avartarBackground}>
            <BoxInfo onClick={() => history.push("/")}>
                <Title>
                    {t('NAME_APP')}
                </Title>
                <TitleInfo>Cơ quan chủ quản: UBND tỉnh Hà Giang</TitleInfo>
                <TitleInfo>Địa chỉ: Đường Trần Hưng Đạo - thành phố Hà Giang - tỉnh Hà Giang</TitleInfo>
                <TitleInfo>Điện thoại: 0219.3866458 Fax: 0219.3868912</TitleInfo>
                <TitleInfo>E-mail: stnmt@hagiang.gov.vn</TitleInfo>
            </BoxInfo>
        </Info>
    )
}

const BoxInfo = styled.div`
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    padding: 15px;
    justify-content: center;
    height: calc((100%)/3);
    width: calc((100%)/2);
    border-radius: 16px;
    background-color: ${({ theme }) => theme.itemMaster};
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    :hover {
        box-shadow: ${({ theme }) => theme.hoverItemMaster};
      }
`

const Title = styled.div`
    width: 100%
    text-align: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 800;
    margin: 2px;
`

const TitleInfo = styled.div`
    width: 100%
    text-align: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 2px;
`

const Info = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url(${(props) => props.imgsrc});
  position: relative;
  z-index: 1;
  opacity: 0.7;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  :before {
    content: "";
    display: block;
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-color: rgba(54,84,99,.7);
    background-color: rgb(140 164 164 / 78%);
  }
`;

export default InfoContainer